import dayjs from "dayjs";

import { CountPartsSetting } from "./type";

const tommorow = dayjs().add(1, "day");
export const defaultCountDownPartsSetting: CountPartsSetting = {
  type: "Count",
  common: {
    x: "0%",
    y: "0%",
    width: "100%",
    height: "100%",
  },
  clock: {
    type: "Second",
    target: tommorow.format(),
    direction: "CountDown",
    text: "{CLOCK}",
    delay: 300,
    color: "#fff",
    fontSize: 0.15,
    fontWeight: "normal",
    whiteSpace: "pre-wrap",
  },
};

export const defaultCountPartsSetting = defaultCountDownPartsSetting;
