import dayjs from "dayjs";
import React, { useCallback } from "react";

type DatetimeInputProps = {
  className?: string;
  value?: dayjs.Dayjs;
  onChange?: (datetime: dayjs.Dayjs) => void;
} & Omit<React.ComponentProps<"input">, "type" | "value" | "onChange">;

export const DatetimeInput: React.FC<DatetimeInputProps> = ({
  value,
  onChange,
  ...rest
}) => {
  const handelOnChange = useCallback(
    (event: React.SyntheticEvent<HTMLInputElement>) => {
      const newDatetime = dayjs(event.currentTarget.value);
      if (newDatetime.isValid() && onChange) {
        onChange(newDatetime);
      }
    },
    [onChange]
  );

  return (
    <input
      type="text"
      value={value ? value.format() : undefined}
      onChange={handelOnChange}
      {...rest}
    />
  );
};
